<template>
  <div v-if="showLoading" :class="{ loading: true, 'is-loading': showCss }">
    <div class="loading-display">
      <CSpinner />
      <div class="loading-text">
        {{ $t("common.text.loading") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CSpinner } from "@coreui/vue/dist/esm/components/spinner";
import { useLoading } from "~/composables/useLoading";

const { isLoading } = useLoading();
const showCss = ref(false);
watch(
  isLoading,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      if (newVal) {
        showLoading.value = true;
        setTimeout(() => {
          showCss.value = true;
        });
      } else {
        showCss.value = false;
        setTimeout(() => {
          showLoading.value = false;
        }, 300);
      }
    }
  },
  {
    immediate: true,
  },
);
const showLoading = ref(false);
</script>
<style scoped>
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  padding: 5rem;
  opacity: 0;
  transition: all 0.3s ease;
}
.is-loading.loading {
  opacity: 1;
}
.loading-display {
  display: flex;
  align-items: center;
}
.loading-text {
  margin-left: 1rem;
}
</style>
