<script setup>
import { onMounted, ref } from "vue";
import {
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/vue/dist/esm/components/breadcrumb/index.js";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "~/stores/locale.js";
const { t } = useI18n();
const breadcrumbs = ref();
const localeStore = useLocaleStore();
const router = useRouter();
const getBreadcrumbs = () => {
  return router.currentRoute.value.matched.map((route) => {
    let name = route.name;
    if (route.path === "/") {
      name = t("page.name.dashboard");
    } else if (route.meta && route.meta.nameAlias) {
      name = t(route.meta.nameAlias);
    }
    return {
      active: route.path === router.currentRoute.value.fullPath,
      name,
      path: `${router.options.history.base}${route.path}`,
    };
  });
};

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs();
});

onMounted(() => {
  breadcrumbs.value = getBreadcrumbs();
});

watch(
  () => localeStore.getLocale,
  () => {
    nextTick(() => {
      breadcrumbs.value = getBreadcrumbs();
    });
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>

<template>
  <CBreadcrumb class="my-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>
