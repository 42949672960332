import { defineComponent, h, onMounted, ref, resolveComponent } from "vue";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from "@coreui/vue";
import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
import { CIcon } from "@coreui/icons-vue";
import NuxtLink from "#app/components/nuxt-link.js";
import { useMenusStore } from "~/stores/menu.js";

/* const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, ""); */

/* const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
}; */

/* const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
}; */

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const firstRender = ref(true);

    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item, parentPath, depth) => {
      item.items = item.children;
      if (!parentPath) {
        parentPath = "";
      }
      parentPath += "/";
      if (!depth) {
        depth = 0;
      }
      depth++;
      if (item.items) {
        item.component = "CNavGroup";
        return h(
          CNavGroup,
          {
            as: "div",
            class: "ps-" + depth * 2,
            compact: true,
          },
          {
            togglerContent: () => [
              h(CIcon, {
                customClassName: "nav-icon",
                name: item.icon,
              }),
              item.name,
            ],
            default: () =>
              item.items.map((child) =>
                renderItem(child, parentPath + item.alias),
              ),
          },
        );
      } else {
        item.component = "CNavItem";
        item.to = parentPath + item.alias;
      }
      return item.to
        ? h(
            NuxtLink,
            {
              to: item.to,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    as: "div",
                    class: "ps-" + depth * 2,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      item.icon
                        ? h(CIcon, {
                            customClassName: "nav-icon",
                            name: item.icon,
                          })
                        : h(
                            "span",
                            { class: "nav-icon" },
                            h("span", { class: "nav-icon-bullet" }),
                          ),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: "ms-auto",
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {
              as: "div",
            },
            {
              default: () => item.name,
            },
          );
    };
    const menusStore = useMenusStore();
    const nav = menusStore.getMenus;
    return () =>
      h(
        CSidebarNav,
        {
          as: simplebar,
        },
        {
          default: () => nav.map((item) => renderItem(item)),
        },
      );
  },
});

export { AppSidebarNav };
