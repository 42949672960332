<script setup>
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/vue/dist/esm/components/dropdown/index.js";
import { CAvatar } from "@coreui/vue/dist/esm/components/avatar/index.js";
import CIcon from "@coreui/icons-vue";
import { useI18n } from "vue-i18n";
import avatar from "~/assets/img/avatars/8.jpg";
import { useUserStore } from "~/stores/user.js";
const { t } = useI18n();

const userStore = useUserStore();
const user = userStore.getUser;

function handleUserInfo(e) {
  e.stopPropagation();
  e.preventDefault();
}
function signOut() {
  const { clearTokens } = useAuth();
  clearTokens();

  const userStore = useUserStore();
  userStore.setUser(undefined);
  makeToast(t("result.message.signed_out"));
  navigateTo("/login?from=v3");
}
</script>

<template>
  <CDropdown placement="bottom-end" variant="nav-item">
    <CDropdownToggle class="py-0 pe-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <div
        v-if="user"
        class="user-info text-center"
        @click="handleUserInfo($event)"
      >
        <CAvatar :src="avatar" size="md" /><br />
        <span class="user-name">{{ user.nm }}<br /></span>
        <span v-if="user.email" class="user-email">{{ user.email }}<br /></span>
        <span class="user-login-id">{{ user.loginId }}</span>
      </div>
      <CDropdownDivider />
      <!-- CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold mb-2 rounded-top"
      >
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold my-2"
      >
        Settings
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem -->
      <CDropdownItem role="button" @click="signOut($event)">
        <CIcon icon="cil-lock-locked" /> {{ $t("common.button.sign_out") }}
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<style scoped>
.user-name {
  font-size: 1rem;
}
.user-email {
  font-size: 0.8rem;
}
.user-login-id {
  font-size: 0.9rem;
}
.user-info {
  padding: 0.5rem;
}
</style>
