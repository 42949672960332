<script setup>
import {
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarToggler,
} from "@coreui/vue/dist/esm/components/sidebar/index.js";
import { CCloseButton } from "@coreui/vue/dist/esm/components/close-button/index.js";
import { CImage } from "@coreui/vue/dist/esm/components/image/index.js";
import { useSidebarStore } from "~/stores/sidebar.js";

import { AppSidebarNav } from "~/components/AppSidebarNav.js";

const sidebar = useSidebarStore();
if (sidebar.visible === undefined) {
  sidebar.visible = true;
}
</script>

<template>
  <CSidebar
    class="border-end"
    color-scheme="dark"
    position="fixed"
    :unfoldable="sidebar.unfoldable"
    :visible="sidebar.visible"
  >
    <CSidebarHeader class="border-bottom">
      <RouterLink v-slot="{ href, navigate }" custom to="/">
        <CSidebarBrand
          class="sidebar-header"
          v-bind="$attrs"
          as="a"
          :href="href"
          @click="navigate"
        >
          <CImage
            class="sidebar-brand"
            rounded
            src="/webframework/img/logo.png"
            height="32px"
          />
          <span class="website-title">
            {{ $t("common.title.website") }}({{
              $t("common.title.website_en")
            }})
          </span>
        </CSidebarBrand>
      </RouterLink>
      <CCloseButton
        class="d-lg-none"
        dark
        @click="sidebar.toggleVisible(!sidebar.visible)"
      />
    </CSidebarHeader>
    <AppSidebarNav />
    <CSidebarFooter class="border-top d-none d-lg-flex">
      <CSidebarToggler @click="sidebar.toggleUnfoldable()" />
    </CSidebarFooter>
  </CSidebar>
</template>

<style scoped>
>>> .sidebar-brand {
  display: flex;
  align-items: center;
}
.sidebar-header {
  justify-content: center;
  padding: 0;
  min-height: 4rem;
}
.website-title {
  margin-left: 0.5rem;
  text-decoration: none !important;
}
</style>
